<template>
  <div>
    <crud-table
      ref="crudTable"
      :list-items="listItems"
      :is-editable="false"
      heading="Reviews"
      row-click-path="/tables/reviews"
      endpoint="reviews"
      @create="createReview()"
      @delete="id => deleteReview(id)"
      @restore="id => restoreReview(id)"
    />

    <edit-dialog
      :show.sync="editDialog.display"
      :max-width="'600'"
      @dialogConfirm="editDialog.saveFunction">
      <template>
        <v-container grid-list-xl>
          <v-layout
            row
            wrap>
            <v-flex
              xs12
              sm6>
              <v-select
                :items="subjectList"
                v-model="editDialog.data.subject"
                :rules="[v => Boolean(v) || 'Please select a subject']"
                label="Subject"
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <InputHotKeyWrapper>
                <v-text-field
                  v-model="editDialog.data.rating"
                  :max-length="TEXT_FIELD_MAX_LENGTH"
                  :rules="[v => Boolean(v) || 'Please provide a rating']"
                  type="number"
                  label="Rating"
                />
              </InputHotKeyWrapper>
            </v-flex>

            <v-flex
              v-if="editDialog.data.subject !== REVIEW_SUBJECT_APP && editDialog.type != 'edit'"
              xs12
              sm6>
              <passengers-input
                v-model="editDialog.data.passenger"
                label="Customer id" />
            </v-flex>
            <v-flex
              v-if="editDialog.data.subject !== REVIEW_SUBJECT_APP && editDialog.type != 'edit'"
              xs12
              sm6>
              <drivers-input
                v-model="editDialog.data.driver"
                label="Driver id" />
            </v-flex>
            <v-flex
              v-if="editDialog.data.subject !== REVIEW_SUBJECT_APP && editDialog.type != 'edit'"
              xs12
              sm6>
              <v-autocomplete
                v-model="editDialog.data.ride"
                :items="rideList"
                :item-text="(val) => { return 'id: ' + val.id }"
                label="Ride id"
                item-value="id"
              />
            </v-flex>
            <v-flex
              xs12
            >
              <v-switch
                v-model="editDialog.data.public"
                label="Public"
              />
            </v-flex>
            <v-flex
              xs12
            >
              <v-switch
                v-model="editDialog.data.anonymous"
                label="Anonymous"
              />
            </v-flex>
            <v-flex
              xs12>
              <v-textarea
                v-model="editDialog.data.explanation"
                :rules="[v => Boolean(v) || 'Please provide a explanation']"
                label="Explanation"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </edit-dialog>
  </div>
</template>

<script>
import CrudTable from '../../../modules/admin/CrudTable.vue'
import PassengersInput from '../../../modules/components/PassengersInput.vue'
import DriversInput from '../../../modules/components/DriversInput.vue'
import Request from '../../../helpers/request'
import { TEXT_FIELD_MAX_LENGTH, REVIEW_SUBJECTS, REVIEW_SUBJECT_APP } from '../../../constants/common'

export default {
  components: { CrudTable, PassengersInput, DriversInput },
  mixins: [Request],
  data () {
    return {
      driverList: [],
      passengerList: [],
      rideList: [],
      subjectList: REVIEW_SUBJECTS,
      REVIEW_SUBJECT_APP,
      TEXT_FIELD_MAX_LENGTH,
      listItems: [
        { text: 'ID', value: 'id' },
        { text: 'Rating', value: 'rating' },
        { text: 'Subject', value: 'subject' },
        { text: 'Explanation', value: 'explanation' },
        { text: 'Created at', value: 'createdAt' },
        { text: 'Modified at', value: 'modifiedAt' },
        { text: '', value: null }
      ],
      loading: true,
      data: [],
      editDialog: {
        display: false,
        saveFunction: () => {},
        data: {}
      }
    }
  },
  beforeMount () {
    let getRideList = () => new Promise((resolve) => {
      this.request('GET', '/rides?pageSize=9999', {}, ({ data }) => {
        this.rideList = data.data
        resolve()
      })
    })

    Promise.all([getRideList()]).then(() => {

    })
  },
  methods: {
    createReview () {
      this.editDialog.display = true
      this.editDialog.data = { }

      this.editDialog.type = 'create'

      this.editDialog.saveFunction = () => {
        let body = {
          data: {
            'rating': this.editDialog.data.rating,
            'subject': this.editDialog.data.subject,
            'explanation': this.editDialog.data.explanation,
            'public': Boolean(this.editDialog.data.public),
            'anonymous': Boolean(this.editDialog.data.anonymous)
          }
        }

        if (this.editDialog.data.subject !== REVIEW_SUBJECT_APP) {
          body = {
            ...body,
            data: {
              ...body.data,
              'passenger': this.editDialog.data.passenger,
              'driver': this.editDialog.data.driver,
              'ride': this.editDialog.data.ride
            }
          }
        }

        this.request('POST', `/reviews`, body, ({ data }) => {
          this.editDialog.display = false
          this.$refs.crudTable.requestData()
          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Review is created'
          })

          this.editDialog.display = false
          this.$refs.crudTable.requestData()
        })
      }
    },
    deleteReview (id) {
      this.request('DELETE', `/reviews/${id}`, {}, ({ data }) => {
        this.$refs.crudTable.requestData()
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'Review is deleted'
        })
      })
    },
    restoreReview (id) {
      this.request('PATCH', `/trash/un-delete/driver-certificate/${id}`, {}, ({ data }) => {
        this.$refs.crudTable.requestData({ deleted: 1 })
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'Review is restored'
        })
      })
    }
  }
}
</script>
